import React from "react"

import AgenciesMarquee from "../components/agencies-marquee"
import Layout from "../components/layout"
import SEO from "../components/seo"
import RequestDemoSection from "../components/request-demo-section"

const ContactUsPage = () => (
  <Layout>
    <SEO
      title="Contact inPowered"
      description="We're here to answer any questions you might have."
    />
    <div class="container pt-6 px-4">
      <RequestDemoSection formPlacement="Contact Page"></RequestDemoSection>
    </div>

    <AgenciesMarquee />
  </Layout>
)

export default ContactUsPage
